import { getApiWrapper, postApiWrapper, deleteApiWrapper } from './token-wrapper-function';

export const getWeddingList = async ({ node_id }) => {
  const result = await getApiWrapper(`/api/wedding_events/user/${node_id}`);
  console.log('Result from getWeddingList', result);
  return result;
};

export const getWeddingDetail = async ({ weddingId }) => {
  const result = await getApiWrapper(`/api/wedding_events/${weddingId}`);
  console.log('Result from getWeddingDetail', result);
  return result;
};

export const getAllWeddingDetail = async () => {
  const result = await getApiWrapper(`/api/wedding_events`);
  console.debug('Result from getAllWeddingDetail', result);

  return result;
};

export const getSubEvents = async ({ weddingId }) => {
  const result = await getApiWrapper(`/api/wedding_events/${weddingId}/subevents`);
  console.log('Result from getSubEvents', result);
  return result;
};

export const getSubEventPhotos = async (weddingId, subEventId) => {
  const result = await getApiWrapper(`/api/wedding_events/${weddingId}/${subEventId}/images?size=2`);
  console.log('Result from getSubEventPhotos', result);
  return result;
};

export const getDefaultSubEvents = async () => {
  const result = await getApiWrapper(`/api/wedding_events/subevents`);
  console.log('Result from getDefaultSubEvents', result);
  return result;
};

export const createWedding = async (data) => {
  const result = await postApiWrapper(`/api/wedding_events`, data);
  console.log('Result from createWedding', result);
  return result;
};

export const createSubEvent = async (data, weddingId) => {
  const result = await postApiWrapper(`/api/wedding_events/${weddingId}/subevent`, data);
  console.log('Result from createSubEvent', result);
  return result;
};

export const getPresignedUrl = async (data) => {
  const result = await postApiWrapper(`/api/wedding_events/file_upload_url`, data);
  console.log('Result from getPresignedUrl', result);
  return result;
};

export const updateWeddingDetails = async (data, weddingId) => {
  const result = await postApiWrapper(`/api/wedding_events/${weddingId}`, data);
  console.log('Result from updateWeddingDetails', result);
  return result;
};

export const uploadSubEventPhotos = async (data) => {
  const result = await postApiWrapper(`/api/wedding_events/${data.event_id}/images`, data);
  console.log('Result from uploadSubEventPhotos', result);
  return result;
};

export const deleteSubEvent = async (weddingId, subEventId) => {
  const result = await deleteApiWrapper(`/api/wedding_events/${weddingId}/subevents/${subEventId}`);
  console.log('Result from deleteSubEvent', result);
  return result;
};

export const deleteSubEventPhoto = async (weddingId, subEventId, subEventPhotoId) => {
  const result = await deleteApiWrapper(`/api/wedding_events/${weddingId}/${subEventId}/images/${subEventPhotoId}`);
  console.log('Result from deleteSubEvent', result);
  return result;
};

export const updateSubEvent = async (weddingId, subEventId, data) => {
  const result = await postApiWrapper(`/api/wedding_events/${weddingId}/subevents/${subEventId}`, data);
  console.log('Result from updateSubEvent', result);
  return result;
};

export const moveSubeventPhotos = async (weddingId, data) => {
  const result = await postApiWrapper(`/api/wedding_events/${weddingId}/images/move`, data);
  console.log('Result from moveSubeventPhotos', result);
  return result;
};

export const getFileBymd5Sum = async (weddingId, md5Code) => {
  const result = await getApiWrapper(
    `/api/wedding_events/${weddingId}/search_file?md5sum=${encodeURIComponent(md5Code)}`,
  );
  console.log('Result from getFileBymd5Sum', result);
  return result;
};
