import React, { useState, useEffect } from 'react';
import { getAllWeddingDetail } from '../../actions/weddingAction';
import LoadingSpinner from '../loader';
import WeddingBox from './weddingBox';
import { Box } from '@material-ui/core';
import { getLoggedInUserId } from '../../actions/authAction';
import { toast } from 'react-toastify';

const WeddingList = () => {
  const [weddings, setWeddings] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getList = async () => {
    try {
      setLoading(true);
      const loggedInUserId = getLoggedInUserId();
      console.log('Node id', loggedInUserId);
      const response = await getAllWeddingDetail();
      console.log(response);
      setWeddings(response.events);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching wedding list:', error);
      toast.error('Error fetching wedding list');
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner message="Loading Data For Wedding List" />
      ) : (
        <Box>
          {weddings?.map((wedding) => (
            <WeddingBox key={wedding._id} wedding={wedding} />
          ))}
        </Box>
      )}
    </>
  );
};

export default WeddingList;
